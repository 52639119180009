import React from 'react'
import Link from 'gatsby-link'
const NotFoundPage = () => (
  <div>
    <h1>This page does not exist!</h1>
    <p>You can go back to our home page here: <Link to="/">My Proof of Insurance</Link></p>
  </div>
)

export default NotFoundPage
